.Center {
  position: fixed;
  width: 400px;
  padding: 0rem;
  height: calc(100vh - 6rem);
  z-index: 1200;
  right: 0rem;
  top: 7.3rem;
  background-color: #fafafa;
  box-shadow: -8px -6px 30px 0px rgba(64, 64, 64, 0.12);
  border: 1px solid #edeff0;
  border-radius: 6px;
}

.Header {
  background-color: #fff;
  padding: 2.5rem 2.2rem 1.5rem;
  border-bottom: 1px solid #edeff0;
  height: 16.5rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.Header .Title {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.Header .List {
  padding: 0;
  border: 1px solid #d6dbdf;
  border-radius: 6px;
  margin-bottom: 2rem;
}
.Header .List .Cell {
  padding: 0.4rem 1rem 0.4rem;
  border-bottom: 1px solid #d6dbdf;
}
.Header .List .Cell:last-child {
  border-bottom: none;
}
.Header .List .Option span {
  font-size: 1.4rem;
}
.Header .List .Option .Icon {
  margin-right: 0.4rem;
}

.Header .List .Preview img {
  height: 3rem;
  width: auto;
}
.Header .Category {
  cursor: pointer;
}
.Header .CategoryIcon {
  margin-right: 0.6rem;
}
.Header .CategoryName {
  font-size: 1.3rem;
  font-weight: 600;
}

.Groups {
  padding-top: 1.3rem;
  height: calc(100% - 16.5rem);
  overflow-y: auto;
}
.Groups .Empty {
  height: calc(100% - 1.3rem);
  font-size: 1.3rem;
}
.Group {
  margin-bottom: 2rem;
}
.Group .GroupHeader {
  margin: 0rem 2.2rem 1rem;
}
.Group .GroupHeader .DeleteIcon {
  cursor: pointer;
}

.Notification {
  position: relative;
  margin-bottom: 1rem;
  overflow-x: hidden;
  padding-left: 2.2rem;
  margin-right: 2.2rem;
}
.Notification .NotificationHeader {
  position: absolute;
  top: 0;
  z-index: 2;
  width: calc(100% - 2.2rem);
  border-radius: 6px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding: 0.2rem 0.7rem 0.3rem 1.2rem;
}
.Notification .NotificationHeader .FeedbackIcon {
  color: #fff;
}
.Notification .Message {
  position: relative;
  min-height: 9rem;
  background-color: #fff;
  padding: 3rem 1.2rem 1.8rem;
  font-size: 1.3rem;
  line-height: 2.1rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f2f2f2;
}
.Notification .Message.Pointer {
  cursor: pointer;
}

.Notification .Time {
  font-size: 1.2rem;
  color: #9b9b9b;
  text-align: right;
  margin-top: 0.2rem;
}
.Notification .Actions {
  position: absolute;
  background-color: #fff;
  height: calc(100% - 1.9rem);
  box-shadow: -5px 1px 12px 0px rgba(0, 0, 0, 0.09);
  top: 1.9rem;
  right: 0rem;
  padding: 0rem 1.6rem;
}
.Notification .Actions .Icon {
  font-size: 2rem;
  cursor: pointer;
}
.Notification .Actions .Icon.Up {
  color: #9ae89f;
}
.Notification .Actions .Icon.Down {
  color: #ff8c8c;
}
.Notification .Unread {
  position: absolute;
  height: calc(100% + 4px);
  top: -4px;
  left: -1.6rem;
  padding: 0rem 0rem;
}
.Notification .Unread .Icon {
  font-size: 0.8rem;
  color: #12AD56;
}
/*
.Settings {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -2px 11px 0px rgba(0,0,0,0.08);
  padding: 1rem 2.2rem 2rem;
}
.Settings .SettingsHeader {
  margin: 0rem 0rem 1rem;
}
.Settings .Title {
  font-weight: 600;
}
.Settings .Title {
  font-weight: 600;
}
*/
