.Title {
  padding: 1.2rem 0rem;
}
.Title .Icon {
  font-size: 1.8rem;
  opacity: 0.6;
  margin-right: 1.2rem;
}
.Title .Label {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.3rem;
}
