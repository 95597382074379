.Access {
  position: fixed;
  right: 2.6rem;
  top: 0.7rem;
  z-index: 2001;
  margin-left: 2rem;
  background-color: #efefef;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}
.Access img {
  position: absolute;
  left: 2rem;
  top: 2rem;
  cursor: pointer;
  transform: translate(-50%, -52%);
  height: 3.7rem;
}
.Access .Bubble {
  position: absolute;
  right: 0.4rem;
  font-size: 0.8rem;
  color: #12AD56;
}

.Access.Big {
  width: 5.4rem;
  height: 5.4rem;
}
.Access.Big img {
  height: 4.6rem;
  left: 2.8rem;
  top: 2.8rem;
}
.Access.Big .Bubble {
  right: 0.8rem;
}
