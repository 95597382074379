@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800&display=swap);
@import url(https://use.typekit.net/kis4gsm.css);
/*@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');*/

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  /*cursor: url('assets/cursor_demo.png') 25 15, auto !important;*/
  outline: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none !important;
}
.clamp p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.Login_Wrapper__2KlkM {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.Login_Content__3x0xM {
  margin: 0rem auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Login_Right__3a23R {
  background-color: #fff;
  border-radius: 18px;
  height: 65vh;
  width: 41vw;
  min-width: 20rem;
  max-width: 55rem;
  min-height: 50rem;
  position: relative;
}
.Login_ClientLogo__3x_qx {
  width: 15rem;
  /*width: auto;*/
  /*height: 2.6rem;*/
  position: absolute;
  right: 3rem;
  top: 2rem;
}
.Login_Form__3wvn6 {
  /*padding: 4rem;*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -44%);
  width: 70%;
}
.Login_Title__iOY-Z {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.4rem;
}
.Login_Help__3W8Oc {
  font-size: 1.6rem;
  font-weight: 300;
  opacity: 0.6;
  margin-bottom: 3.6rem;
}
.Login_InputLabel__1u6mC {
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.Login_Error__339JV {
  height: 5rem;
  font-size: 1.1rem;
  padding: 0.7rem 0rem;
}
.Login_Left__GYK01 {
  position: relative;
  margin-right: 8vw;
}
.Login_Mascot__2aHAG {
  position: absolute;
  top: 0;
  left: -4rem;
}
.Login_Mascot__2aHAG img {
  width: 21rem;
  height: auto;
}
.Login_Cmb__fsJVh {
  padding-left: 13.5rem;
  font-size: 8.7rem;
  font-family: 'proxima-nova, sans-serif';
  font-weight: 700;
  line-height: 1;
  color: #fff;
}

@media (max-width: 1680px) {
  .Login_Form__3wvn6 {
    width: 70%;
  }
  .Login_Right__3a23R {
    height: 65vh;
    width: 35vw;
  }
  .Login_Title__iOY-Z {
    font-size: 2.8rem;
  }
  .Login_Left__GYK01 {
    position: relative;
    margin-right: 5vw;
  }
  .Login_ClientLogo__3x_qx {
    /*height: 2.2rem;*/
  }
  .Login_Mascot__2aHAG {
    top: -0.8rem;
    left: -7rem;
  }
  .Login_Mascot__2aHAG img {
    width: 16rem;
  }
  .Login_Cmb__fsJVh {
    padding-left: 6rem;
    font-size: 6.5rem;
    font-family: 'proxima-nova, sans-serif';
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
}
@media (max-width: 1080px) {
  .Login_Form__3wvn6 {
    width: 75%;
  }
  .Login_Right__3a23R {
    height: 65vh;
    width: 35vw;
  }
  .Login_Title__iOY-Z {
    font-size: 2.2rem;
  }
  .Login_ClientLogo__3x_qx {
    /*height: 2.2rem;*/
  }
  .Login_Mascot__2aHAG {
    top: -0.8rem;
    left: -5.7rem;
  }
  .Login_Mascot__2aHAG img {
    width: 14rem;
  }
  .Login_Cmb__fsJVh {
    padding-left: 6rem;
    font-size: 5rem;
    font-family: 'proxima-nova, sans-serif';
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
}

.Feedback_Script__cy7_V {
  margin-top: 1rem;
  color: #777777;
  line-height: 1.66666;
  margin-bottom: 3rem;
}

.Feedback_Subtitle__1wm7o {
  color: #4c4c4c;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.Feedback_ChipsContainer__2JAKq {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2rem;
}
.Feedback_ChipsBorder__1bpIa {
  box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.2);
  border-radius: 3rem;
}
.Feedback_Chip__RmwbA {
  border-radius: 3rem;
  border: 0.1rem solid transparent;
  padding: 0.7rem 3rem 0.7rem;
  margin-right: 3rem;
  color: #777777;
  text-align: center;
  backgroundcolor: #fff;
  display: inline-block;
  cursor: pointer;
  zindex: 10;
  position: relative;
}
.Feedback_Chip__RmwbA:last-child {
  margin-right: 0rem;
}
.Feedback_Chip__RmwbA.Feedback_Accepted__18A35:hover,
.Feedback_Chip__RmwbA.Feedback_Active__3GeDC.Feedback_Accepted__18A35 {
  color: #12AD56;
}
.Feedback_Chip__RmwbA.Feedback_NotProposed__2xyIt:hover,
.Feedback_Chip__RmwbA.Feedback_Active__3GeDC.Feedback_NotProposed__2xyIt {
  color: #ccbd16;
}
.Feedback_Chip__RmwbA.Feedback_Rejected__y6Puk:hover,
.Feedback_Chip__RmwbA.Feedback_Active__3GeDC.Feedback_Rejected__y6Puk {
  color: #f90000;
}

.Feedback_Chip__RmwbA.Feedback_Undecided__tSZ-m:hover,
.Feedback_Chip__RmwbA.Feedback_Active__3GeDC.Feedback_Undecided__tSZ-m {
  color: #e87f19;
}

.Feedback_Chip__RmwbA.Feedback_Active__3GeDC {
  box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.2);
}

.Feedback_Error__tpOav {
  font-size: 1.1rem;
  padding: 0.7rem 0rem;
}

.NotificationBadge_Title__2HvCr {
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 3.6rem;
  margin-bottom: 1.2rem;
  margin-top: 2rem;
}
.NotificationBadge_Close__-obmc {
  text-align: right;
  cursor: pointer;
}
.NotificationBadge_Counter__2OCev {
  background-color: #f5f5f5;
  padding: 0.4rem 1.6rem;
  border-radius: 13px;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.NotificationBadge_Icon__3kjCm {
  margin: 4rem 0rem;
  width: 11rem;
  height: auto;
  -webkit-filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
          filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
}
.NotificationBadge_SummaryContainer__1wWsd {
  width: 80%;
  margin: 0rem auto;
}
.NotificationBadge_BtContainer__Jl0XD {
  width: 80%;
  margin: 3rem auto 0rem;
}

.NotificationLevel_Title__1y-80 {
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 3.6rem;
  margin-bottom: 1.2rem;
  margin-top: 2rem;
}
.NotificationLevel_Timer__LoGpn {
  position: absolute;
  font-size: 2rem;
  font-weight: 600;
}
.NotificationLevel_Close__Mn8GB {
  text-align: right;
  cursor: pointer;
}
.NotificationLevel_Counter__1FygV {
  background-color: #f5f5f5;
  padding: 0.4rem 1.6rem;
  border-radius: 13px;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.NotificationLevel_Icon__3lfZF {
  margin: 4rem 0rem;
  width: 14rem;
  height: auto;
  -webkit-filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
          filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
}
.NotificationLevel_SummaryContainer__1cib4 {
  width: 80%;
  margin: 0rem auto;
}
.NotificationLevel_BtContainer__21eEB {
  width: 80%;
  margin: 3rem auto 0rem;
}

.NotificationPoints_Title__1dKOu {
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 3.6rem;
  margin-bottom: 1.2rem;
  margin-top: 2rem;
}
.NotificationPoints_Timer__2f706 {
  position: absolute;
  font-size: 2rem;
  font-weight: 600;
}
.NotificationPoints_Close__14dYj {
  text-align: right;
  cursor: pointer;
}
.NotificationPoints_ProgressContainer__3aTMK {
  padding: 4rem 3rem 5rem;
}
.NotificationPoints_SummaryContainer__3j0Gu {
  width: 80%;
  margin: 0rem auto;
}
.NotificationPoints_SummaryContainer__3j0Gu .NotificationPoints_Counter__SFChl {
  background-color: #f5f5f5;
  padding: 0.4rem 1.6rem;
  border-radius: 13px;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.NotificationPoints_BtContainer__2A0q8 {
  width: 80%;
  margin: 3rem auto 0rem;
}

.Progress_Progress__1lg0h {
  position: relative;
  margin: 0rem 1rem;
  min-height: 12rem;
}

.Progress_CurrentLevelIcon__cEx3S {
  position: absolute;
  top: 7.3rem;
  left: 0.7rem;
  transform: translateX(-50%);
  height: 3.6rem;
  width: auto;
}
.Progress_PrevPts__4oUzR {
  position: absolute;
  top: 10.9rem;
  left: 0.7rem;
  transform: translateX(-50%);
  font-size: 1.3rem;
}
.Progress_NextLevelIcon__fKwHc {
  position: absolute;
  top: 7.3rem;
  right: 0.7rem;
  transform: translateX(50%);
  height: 3.6rem;
  width: auto;
}
.Progress_NextPts__2ER7Z {
  position: absolute;
  top: 10.9rem;
  right: 0.7rem;
  transform: translateX(50%);
  font-size: 1.3rem;
}

.Progress_Bar__3Ldyf {
  position: relative;
  top: 0;
  left: 3rem;
  width: calc(100% - 6rem);
}
.Progress_Bar__3Ldyf .Progress_Avatar__3mas2 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  width: 6.2rem;
  height: auto;
  z-index: 20;
  border: 1px solid #cecece;
}
.Progress_Bar__3Ldyf .Progress_Line__1ua93 {
  position: absolute;
  top: 6.2rem;
  left: 0;
  width: 2px;
  height: 2.7rem;
  background-color: #cecece;
  z-index: 20;
}
.Progress_Bar__3Ldyf .Progress_BgBar__3Ah-I {
  position: absolute;
  top: 8.9rem;
  left: 0rem;
  width: 100%;
  height: 0.9rem;
  background-color: #dadada;
  border-radius: 5px;
}
.Progress_Bar__3Ldyf .Progress_CurrentLevelBar__X_S0z {
  position: absolute;
  top: 8.9rem;
  left: 0rem;
  width: 0;
  height: 0.9rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Progress_Bar__3Ldyf .Progress_CurrentPts__Rdmib {
  position: absolute;
  top: 10.9rem;
  left: 0;
  transform: translateX(-50%);
  font-size: 1.3rem;
  font-weight: 600;
  white-space: nowrap;
  background-color: #fff;
  z-index: 20;
  padding: 0px 5px;
}

@keyframes HomeAgent_fadeIn__10SpF {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.HomeAgent_FadeIn__1OLTw {
  animation: HomeAgent_fadeIn__10SpF ease 0.4s;
  -webkit-animation: HomeAgent_fadeIn__10SpF ease 0.4s;
  -moz-animation: HomeAgent_fadeIn__10SpF ease 0.4s;
  -o-animation: HomeAgent_fadeIn__10SpF ease 0.4s;
  -ms-animation: HomeAgent_fadeIn__10SpF ease 0.4s;
}
@keyframes HomeAgent_translate__aMSIP {
  from {
    padding-bottom: 15rem;
  }
  to {
    padding-bottom: 0rem;
  }
}
.HomeAgent_Translate__wFNzC {
  animation: HomeAgent_translate__aMSIP ease 0.4s;
  -webkit-animation: HomeAgent_translate__aMSIP ease 0.4s;
  -moz-animation: HomeAgent_translate__aMSIP ease 0.4s;
  -o-animation: HomeAgent_translate__aMSIP ease 0.4s;
  -ms-animation: HomeAgent_translate__aMSIP ease 0.4s;
}

.HomeAgent_Wrapper__3cFyK {
  padding: 51px 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(0deg, transparent 50%, #ffffff 50%);
  min-height: 100vh;
}

.HomeAgent_WrapperTop__HQDaz {
  /*position: relative;*/
  width: 100%;
  /*height: auto;*/
  /*height: calc(50vh - 51px);*/
  text-align: center;
  /*background-color: yellow;*/
  padding-bottom: 0rem;
  background-color: white;
  box-shadow: 0rem 0.3rem 0.4rem 0rem rgba(1, 0, 0, 0.02);
}
.HomeAgent_WrapperTop__HQDaz.HomeAgent_ShowAvatar__2Pzxu {
  padding-bottom: 15rem;
}

.HomeAgent_WrapperTop__HQDaz .HomeAgent_Welcome__31004 {
  font-weight: 600;
  font-size: 4.1rem;
  line-height: 0.95;
  color: #4c4c4c;
  margin-bottom: 1.5rem;
}
.HomeAgent_WrapperTop__HQDaz .HomeAgent_Time__1CBYW {
  font-weight: 800;
}
.HomeAgent_WrapperTop__HQDaz .HomeAgent_Help___-1pA {
  color: #6b6b6b;
  margin-bottom: 5rem;
}
.HomeAgent_StartCall__1CA84 {
  margin-bottom: 1.5rem;
}

.HomeAgent_WrapperBottom__UlmE4 {
  width: 100%;
  padding: 3rem 2rem;
  background-color: #f5f7fa;
}
.HomeAgent_Hidden__2VRRe {
  opacity: 0;
}
.HomeAgent_WrapperBottom__UlmE4 .HomeAgent_FeedbackTitle__2TNlt {
  font-weight: 600;
  text-align: left;
  margin-top: 0.8rem;
  margin-bottom: 2.7rem;
}
.HomeAgent_WrapperBottom__UlmE4 .HomeAgent_FeedbackCard__1Urbh {
  text-align: left;
  padding: 3rem;
  width: 28.4rem;
  height: 28rem;
  color: #777777;
}
.HomeAgent_WrapperBottom__UlmE4 .HomeAgent_FeedbackCard__1Urbh .HomeAgent_CustomerName__lVy0m {
  color: #4c4c4c;
  font-weight: 600;
  font-size: 1.8rem;
  min-height: 7.4rem;
}
.HomeAgent_WrapperBottom__UlmE4 .HomeAgent_FeedbackCard__1Urbh .HomeAgent_CustomerInfo__3YFvn {
  margin-bottom: 0.5rem;
}
.HomeAgent_WrapperBottom__UlmE4 .HomeAgent_FeedbackCard__1Urbh .HomeAgent_FeedbackStatus__wyqsA {
  font-weight: 600;
  margin-left: 1rem;
}

.AvatarChoice_badgesContainer__2zqfa {
  text-align: left;
  padding: 4.8rem;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.07);
  borderradius: 3px;
  margin-bottom: 2.4rem;
  background-color: #fff;
}
.AvatarChoice_Title__1B_kc {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.3rem;
  margin-bottom: 1.6rem;
}
.AvatarChoice_Message__uueIO {
  margin-bottom: 5.2rem;
}

.Access_FeedbackTitle__13Okb {
  font-weight: 600;
  text-align: left;
  margin-bottom: 1.8rem;
}

.Access_FeedbackCard__1utjh {
  text-align: left;
  padding: 3rem;
  width: 28.4rem;
  height: 28rem;
  color: #777777;
  text-align: center;
}

.Access_Level__31uM8 {
  margin-bottom: 2rem;
}

.Access_KeyInfo__3v8gq .Access_Key__73-Kf {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  background-color: #f5f5f5;
  padding: 0.3rem 0 0.6rem 0;
  border-radius: 3px;
  margin-right: 0.6rem;
  width: 9rem;
}
.Access_KeyInfo__3v8gq .Access_Key__73-Kf:last-child {
  margin-right: 0rem;
}
.Access_KeyInfo__3v8gq .Access_Key__73-Kf .Access_Value__NUnqu {
  font-size: 2.4rem;
  line-height: 3.3rem;
  font-weight: 600;
}
.Access_KeyInfo__3v8gq .Access_Key__73-Kf .Access_Label__2dB3p {
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 600;
  opacity: 0.5;
}

.GamificationWrapper_Wrapper__3EMhR {
  margin-top: 51px;
  box-shadow: inset 0px 2px 9px 0px rgba(1, 0, 0, 0.02);
  padding: 2.4rem 2.4rem 0rem;
  min-height: calc(100vh - 51px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.GamificationWrapper_Activity__38Cz8.GamificationWrapper_Transparent__2xM5Q {
  height: 0.3rem;
  background-color: #fff;
  width: calc(100vw + 2rem);
  position: fixed;
  top: 0;
  left: -2rem;
  z-index: 2000;
}
.GamificationWrapper_Box__10Cqj {
  box-shadow: 0px 2px 70px -7px rgba(0, 0, 0, 0.06);
  border-radius: 6;
  background-color: #fff;
  min-height: calc(100vh - 120px);
}
.GamificationWrapper_Box__10Cqj .GamificationWrapper_Right__2K6ns {
  padding: 3.3rem 3.5rem;
}

.Menu_Wrapper__2Xc7V {
  box-shadow: inset -35px 0px 35px -35px rgba(0, 0, 0, 0.08);
  min-height: 100%;
  padding: 2.4rem 3.5rem;
}

.Menu_Profile__1QApD {
  text-align: center;
  margin-bottom: 4rem;
}
.Menu_Profile__1QApD .Menu_Name__22shL {
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 2.3rem;
}
.Menu_Profile__1QApD .Menu_Key__3pyAf {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  background-color: #f5f5f5;
  padding: 0.3rem 0 0.6rem 0;
  border-radius: 3px;
  margin-right: 0.6rem;
  width: 9rem;
}
.Menu_Profile__1QApD .Menu_Key__3pyAf:last-child {
  margin-right: 0rem;
}
.Menu_Profile__1QApD .Menu_Key__3pyAf .Menu_Value__3xL9p {
  font-size: 2.9rem;
  line-height: 3.9rem;
  font-weight: 600;
}
.Menu_Profile__1QApD .Menu_Key__3pyAf .Menu_Label__qMzDp {
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 600;
  opacity: 0.5;
}

.Card_Title__2UNh8 {
  padding: 1.2rem 0rem;
}
.Card_Title__2UNh8 .Card_Icon__2ONGw {
  font-size: 1.8rem;
  opacity: 0.6;
  margin-right: 1.2rem;
}
.Card_Title__2UNh8 .Card_Label__3N52W {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.3rem;
}

.Level_Wrapper__3crL1 {
  background-color: #fff;
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  height: 100%;
}
.Level_Current__2qSZT {
  background-color: #fcfcfc;
  padding: 2.2rem 2.4rem;
  min-height: 100%;
  text-align: center;
}
.Level_Current__2qSZT .Level_Title__2NJ_4 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Level_Current__2qSZT .Level_Icon__3sRxK {
  width: auto;
  height: 15rem;
  min-width: 5rem;
  max-width: 9.7rem;
}
.Level_Current__2qSZT .Level_Points__3ndpw {
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 1.5rem;
}

.Level_Map__nMPWX {
  padding: 3rem 3.5rem;
  /*box-shadow: inset 35px 0px 35px -35px rgba(0,0,0,0.08);*/
  min-height: 100%;
}
.Level_Map__nMPWX .Level_Message__3A9uL {
  margin-bottom: 3rem;
}
.Level_Map__nMPWX .Level_Message__3A9uL span {
  font-weight: 700;
}

.Ranking_Wrapper__QdFrX {
  background-color: #fff;
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  padding: 2.2rem;
  height: 100%;
}

.Ranking_Table__1quqO .Ranking_CellRank__1D984 {
  width: auto;
  white-space: nowrap;
  border-bottom: none;
  padding-bottom: 0.6rem;
  font-weight: 600;
  fontsize: 1.2rem;
  color: #969696;
}
.Ranking_Table__1quqO .Ranking_CellRank__1D984.Ranking_Me__A2zel {
  color: #00de7c;
}
.Ranking_Table__1quqO .Ranking_CellAvatar__2QMAy {
  padding: 0rem 1.3rem;
  border-bottom: none;
  padding-bottom: 0.6rem;
}
.Ranking_Table__1quqO .Ranking_CellAvatar__2QMAy Avatar {
  height: 4.2rem;
  width: 4.2rem;
}
.Ranking_Table__1quqO .Ranking_CellProgressBar__-25rY {
  width: 100%;
  border-bottom: none;
  padding-bottom: 0.6rem;
}
.Ranking_Table__1quqO .Ranking_CellProgressBar__-25rY .Ranking_Bg__324Q7 {
  background-color: #f2f2f2;
  width: 100%;
  height: 4px;
  margin-bottom: 0.8rem;
  margin-top: 0.2rem;
}
.Ranking_Table__1quqO .Ranking_CellProgressBar__-25rY .Ranking_Name__2BFVa {
  flex-grow: 1;
  color: #3a404d;
  font-size: 1.3rem;
  line-height: 1.2;
}
.Ranking_Table__1quqO .Ranking_CellProgressBar__-25rY .Ranking_Points__1Kikn {
  color: #3a404d;
  font-size: 1.3rem;
  text-align: right;
}
.Ranking_Table__1quqO .Ranking_CellProgressBar__-25rY .Ranking_Points__1Kikn.Ranking_Me__A2zel {
  color: #00de7c;
}
.Ranking_Table__1quqO .Ranking_CellProgressBar__-25rY .Ranking_Progress__2ojy- {
  height: 4px;
}

.Ranking_Target__2uV5m {
  text-align: center;
  line-height: 0;
}
.Ranking_Target__2uV5m .Ranking_Avatar__2ezhO {
  width: 7rem;
  height: auto;
  margin: 0 auto;
}
.Ranking_Target__2uV5m .Ranking_Dots__3jRWU img {
  margin: 0.8rem 0;
}
.Ranking_Target__2uV5m .Ranking_Message__39v_c {
  width: 90%;
  transform: translateX(10%);
  font-size: 1.3rem;
  line-height: 1.4;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.Ranking_LDTitle__2u11e {
  padding: 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.3rem;
}
.Ranking_LDClose__1zeM- {
  position: absolute;
  right: 2.4rem;
  top: 2.4rem;
  cursor: pointer;
}
.Ranking_LDDivider__aouor {
  width: 100%;
  height: 1px;
  background-color: #e6e9ee;
}
.Ranking_LDButtons__2oPqz {
  text-align: right;
  padding: 1.7rem 2.4rem;
}
.Ranking_LDList__wB_oP {
  padding: 3rem 6rem 3rem 4rem;
}

.Badges_SectionTitle__31pQ8 {
  margin-bottom: 2rem;
}

.BadgeWon_Wrapper__3Qu3N {
  width: 24rem;
  height: 26rem;
  padding: 2.4rem 2rem 2.2rem;
  background-color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  position: relative;
  text-align: center;
}
.BadgeWon_Image__ikKWl {
  margin: 0 auto;
  margin-bottom: 1rem;
  height: 10rem;
  width: 10rem;
}
.BadgeWon_Image__ikKWl img {
  height: 100%;
  width: auto;
  -webkit-filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
          filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
}
.BadgeWon_Name__5bbSw {
  font-weight: 600;
}
.BadgeWon_Description__vc14U {
  font-size: 1.3rem;
}

.BadgeUpcoming_Wrapper__2d4DW {
  width: 17rem;
  height: 12.2rem;
  padding: 1.4rem;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  text-align: center;
  background-color: #fff;
}
.BadgeUpcoming_Image__3LMXj {
  margin: 0 auto;
  margin-bottom: 0.8rem;
  height: 6rem;
  width: 6rem;
  opacity: 0.7;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.BadgeUpcoming_Image__3LMXj img {
  height: 100%;
  width: auto;
  -webkit-filter: drop-shadow(7px 8px 7px rgba(0, 0, 0, 0.12));
          filter: drop-shadow(7px 8px 7px rgba(0, 0, 0, 0.12));
}
.BadgeUpcoming_Name__1Ic8h {
  font-weight: 600;
  line-height: 2rem;
}

.GamificationLevels_Message__1X05n {
  margin-bottom: 3rem;
}
.GamificationLevels_Message__1X05n span {
  font-weight: 700;
}
.GamificationLevels_Path__3TsbN {
  /*background: yellow;*/
}
.GamificationLevels_PathLine__VO-27 {
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  /*background: pink;*/
  background: #fff;
  border-radius: 4rem;
  padding: 2.2rem 3rem;
  margin-top: 3rem;
}
.GamificationLevels_Tick__34HH5 {
  position: absolute;
  font-size: 2.2rem;
  line-height: 2.2rem;
  color: #eeeeee;
}
.GamificationLevels_Tick__34HH5.GamificationLevels_active__2xV3v {
  color: #13ae57;
}
.GamificationLevels_BgBar__27XCA {
  position: relative;
  background: #eeeeee;
  border-radius: 1.1rem;
  height: 2.2rem;
  margin-left: 4rem;
}
.GamificationLevels_FillBar__2uB0U {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #0000ff;
  border-radius: 1.1rem;
  height: 2.2rem;
}
.GamificationLevels_PtsBar__2eLZt {
  position: absolute;
  right: 0.6rem;
  top: 2.3rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.GamificationLevels_PathLevels__mDOW1 {
  padding: 2.2rem 3rem;
}

.GamificationLevels_LevelGridItem__3bkqC {
  /*background-color: orange;*/
  /*border: 1px solid red;*/
}
.GamificationLevels_LevelCard__38sVe {
  background-color: #fff;
  /*border: 1px solid grey;*/
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  text-align: center;
  padding: 1rem 1rem;
  border-radius: 3px;
  /*background-color: pink;*/
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /*min-width: 10rem;*/
  /*max-width: 20rem;*/
}
.GamificationLevels_LevelCard__38sVe .GamificationLevels_Title__37YPg {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.GamificationLevels_LevelCard__38sVe .GamificationLevels_Icon__3D2Xo {
  /*background-color: yellow;*/
  max-width: 30rem;
  /*max-width: 9rem;*/
  /*min-width: 5rem;*/
  /*max-width: 9.7rem;*/
}
.GamificationLevels_LevelCard__38sVe .GamificationLevels_Icon__3D2Xo img {
  height: 100%;
  /*  max-width: 100%*/
  padding: 0rem 1rem;
}
.GamificationLevels_LevelCard__38sVe .GamificationLevels_Points__D_jby {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.5rem;
  white-space: nowrap;
}
.GamificationLevels_Avatar__10LqN {
  position: absolute;
  top: -5.5rem;
  left: 0rem;
  transform: translateX(-50%);
  width: 6.2rem;
  height: auto;
  z-index: 20;
  border: 1px solid #cecece;
}
.GamificationLevels_Line__3djOV {
  position: absolute;
  top: -1.6rem;
  left: -0.5rem;
  width: 2px;
  height: 1.7rem;
  background-color: #cecece;
  z-index: 20;
}

.Monitoring_Wrapper__3Hy2z {
  width: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  margin: 0rem auto;
}

.Overall_figuresTitle__17Lth {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 0.9rem;
}
.Overall_figureWrapper__1uz6w {
  display: inline-block;
  margin-right: 2rem;
}
.Overall_figureLabel__17ehc {
  font-size: 1rem;
  text-transform: uppercase;
  color: #3a404d;
  opacity: 0.9;
}
.Overall_figureValue__1yJ3h {
  font-size: 1.8rem;
}
.Overall_divider__2A2AO {
  margin-bottom: 3rem;
}

.Notifications_Status__3AqfV {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 0.6rem;
  border-radius: 3px;
}
.Notifications_Upcoming__1XIGv {
  background-color: #659ad0;
}
.Notifications_Ongoing__3YAvw {
  background-color: #3fbb77;
}
.Notifications_Archived__3ZfBs {
  background-color: #c5c5c5;
}

.Notifications_Icon__3z9J3 {
  font-size: 1.8rem;
  margin-right: 1rem;
}
.Notifications_Icon__3z9J3.Notifications_Up__3xX5z {
  color: #7ed283;
}
.Notifications_Icon__3z9J3.Notifications_Down__3q4bH {
  color: #ff8c8c;
  vertical-align: middle;
}
.Notifications_Icon__3z9J3.Notifications_Trash__2M8JQ {
  font-size: 1.6rem;
  color: #d85656;
  opacity: 1;
  cursor: pointer;
}
.Notifications_Icon__3z9J3.Notifications_Trash__2M8JQ.Notifications_Disabled__2chtn {
  opacity: 0.3;
  cursor: default;
}
.Notifications_Icon__3z9J3.Notifications_Add__2wynU {
  font-size: 1.6rem;
  color: #13ae57;
}
.Notifications_AndOr__wt_b1 {
  height: 3.1rem;
  width: 12.6rem;
  position: relative;
}
.Notifications_BtAndOr__2NWWK {
  background-color: #fff;
  border-radius: 22px;
  text-align: center;
  padding: 0.4rem 2rem;
  font-weight: 600;
  position: absolute;
}
.Notifications_BtAndOr__2NWWK:last-child {
  right: 0;
}
.Notifications_BtAndOr__2NWWK:first-child.Notifications_Unselected__1LKS7 {
  padding: 0.4rem 3rem 0.4rem 2rem;
}
.Notifications_BtAndOr__2NWWK:last-child.Notifications_Unselected__1LKS7 {
  padding: 0.4rem 2rem 0.4rem 3rem;
}
.Notifications_BtAndOr__2NWWK.Notifications_Unselected__1LKS7 {
  border: 1px solid #bcbcbc;
  color: #787878;
  z-index: 9;
  cursor: pointer;
}
.Notifications_BtAndOr__2NWWK.Notifications_Selected__2AT5K {
  border: 1px solid #13ae57;
  color: #13ae57;
  z-index: 10;
}

.Notifications_AddGroupWrapper__rLXH2 {
  position: relative;
  margin: 0.5rem 0rem 1.5rem;
}
.Notifications_AddGroupWrapper__rLXH2 .Notifications_Line__FUtdH {
  position: absolute;
  left: 0px;
  top: 14px;
  border-bottom: 2px #bcbcbc dashed;
  height: 2px;
  width: 100%;
}
.Notifications_AddGroupWrapper__rLXH2 .Notifications_Line__FUtdH.Notifications_Left__3xM9X {
  left: 0px;
  width: 40%;
}
.Notifications_AddGroupWrapper__rLXH2 .Notifications_Line__FUtdH.Notifications_Right__nBaGg {
  left: 60%;
  width: 40%;
}
.Notifications_AddGroupWrapper__rLXH2 .Notifications_AddGroup__WjCIM {
  cursor: pointer;
  position: absolute;
  top: 4px;
  left: 40%;
  width: 20%;
  text-align: center;
}

.PushNotifications_Bundle__yT5Gc {
  position: fixed;
  z-index: 2001;
  right: 0.8rem;
  top: 0.6rem;
}
.PushNotifications_Notification__3d86k.PushNotifications_Micro__360ce {
  width: auto;
  padding-right: 1.4rem;
  cursor: pointer;
}
.PushNotifications_Notification__3d86k.PushNotifications_Micro__360ce .PushNotifications_Message__zHn7q {
  font-size: 3rem;
  width: 7rem;
}
.PushNotifications_Notification__3d86k.PushNotifications_Micro__360ce .PushNotifications_Message__zHn7q {
  font-size: 3rem;
  padding: 0.6rem 2.2rem;
}

.PushNotifications_Notification__3d86k {
  width: 41.5rem;
  position: relative;
  height: 7rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 45px;
  box-shadow: 5px 12px 34px 0px rgba(175, 180, 187, 0.43);
}
.PushNotifications_Notification__3d86k.PushNotifications_Exited__11wjQ {
  height: 0rem;
  padding: 0rem;
  margin-bottom: 0rem;
  transition: all 0.1s;
}

.PushNotifications_Notification__3d86k .PushNotifications_Buddy__LoVTa {
  position: absolute;
  top: -1.7rem;
  left: -0.7rem;
}
.PushNotifications_Notification__3d86k .PushNotifications_Buddy__LoVTa img {
  width: 10rem;
  height: auto;
}

.PushNotifications_Notification__3d86k .PushNotifications_Message__zHn7q {
  width: 27.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2rem;
  margin-left: 7rem;
  height: 4.9rem;
  overflow: hidden;
  background-color: #fff;
  padding: 0.4rem 2rem;
  border-radius: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.PushNotifications_Notification__3d86k .PushNotifications_Actions__lEVmI {
  position: absolute;
  right: 2.5rem;
  top: 0rem;
}
.PushNotifications_Notification__3d86k .PushNotifications_Actions__lEVmI > div {
  margin: 1rem 0rem;
}
.PushNotifications_Notification__3d86k .PushNotifications_Actions__lEVmI .PushNotifications_Icon__2JGO3 {
  font-size: 2rem;
  cursor: pointer;
}
.PushNotifications_Notification__3d86k .PushNotifications_Actions__lEVmI .PushNotifications_Icon__2JGO3.PushNotifications_Up__1Bacd {
  color: #cdffd0;
}
.PushNotifications_Notification__3d86k .PushNotifications_Actions__lEVmI .PushNotifications_Icon__2JGO3.PushNotifications_Down__22lD3 {
  color: #ffb1b1;
}

.NotificationCenter_Center__aqU4M {
  position: fixed;
  width: 400px;
  padding: 0rem;
  height: calc(100vh - 6rem);
  z-index: 1200;
  right: 0rem;
  top: 7.3rem;
  background-color: #fafafa;
  box-shadow: -8px -6px 30px 0px rgba(64, 64, 64, 0.12);
  border: 1px solid #edeff0;
  border-radius: 6px;
}

.NotificationCenter_Header__3nCAi {
  background-color: #fff;
  padding: 2.5rem 2.2rem 1.5rem;
  border-bottom: 1px solid #edeff0;
  height: 16.5rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_Title__w-Qml {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_List__1Oc65 {
  padding: 0;
  border: 1px solid #d6dbdf;
  border-radius: 6px;
  margin-bottom: 2rem;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_List__1Oc65 .NotificationCenter_Cell__14DqY {
  padding: 0.4rem 1rem 0.4rem;
  border-bottom: 1px solid #d6dbdf;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_List__1Oc65 .NotificationCenter_Cell__14DqY:last-child {
  border-bottom: none;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_List__1Oc65 .NotificationCenter_Option__1gcEa span {
  font-size: 1.4rem;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_List__1Oc65 .NotificationCenter_Option__1gcEa .NotificationCenter_Icon__TddGI {
  margin-right: 0.4rem;
}

.NotificationCenter_Header__3nCAi .NotificationCenter_List__1Oc65 .NotificationCenter_Preview__ICC52 img {
  height: 3rem;
  width: auto;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_Category__2BrXQ {
  cursor: pointer;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_CategoryIcon__3gOY_ {
  margin-right: 0.6rem;
}
.NotificationCenter_Header__3nCAi .NotificationCenter_CategoryName__2m3qa {
  font-size: 1.3rem;
  font-weight: 600;
}

.NotificationCenter_Groups__3otOj {
  padding-top: 1.3rem;
  height: calc(100% - 16.5rem);
  overflow-y: auto;
}
.NotificationCenter_Groups__3otOj .NotificationCenter_Empty__1RirE {
  height: calc(100% - 1.3rem);
  font-size: 1.3rem;
}
.NotificationCenter_Group__1jI_3 {
  margin-bottom: 2rem;
}
.NotificationCenter_Group__1jI_3 .NotificationCenter_GroupHeader__3kUBd {
  margin: 0rem 2.2rem 1rem;
}
.NotificationCenter_Group__1jI_3 .NotificationCenter_GroupHeader__3kUBd .NotificationCenter_DeleteIcon__pOTL9 {
  cursor: pointer;
}

.NotificationCenter_Notification__1SGTk {
  position: relative;
  margin-bottom: 1rem;
  overflow-x: hidden;
  padding-left: 2.2rem;
  margin-right: 2.2rem;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_NotificationHeader__37gI9 {
  position: absolute;
  top: 0;
  z-index: 2;
  width: calc(100% - 2.2rem);
  border-radius: 6px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding: 0.2rem 0.7rem 0.3rem 1.2rem;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_NotificationHeader__37gI9 .NotificationCenter_FeedbackIcon__Dn1fL {
  color: #fff;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Message__2c-gL {
  position: relative;
  min-height: 9rem;
  background-color: #fff;
  padding: 3rem 1.2rem 1.8rem;
  font-size: 1.3rem;
  line-height: 2.1rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f2f2f2;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Message__2c-gL.NotificationCenter_Pointer__1oE2K {
  cursor: pointer;
}

.NotificationCenter_Notification__1SGTk .NotificationCenter_Time__2spop {
  font-size: 1.2rem;
  color: #9b9b9b;
  text-align: right;
  margin-top: 0.2rem;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Actions__2CepP {
  position: absolute;
  background-color: #fff;
  height: calc(100% - 1.9rem);
  box-shadow: -5px 1px 12px 0px rgba(0, 0, 0, 0.09);
  top: 1.9rem;
  right: 0rem;
  padding: 0rem 1.6rem;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Actions__2CepP .NotificationCenter_Icon__TddGI {
  font-size: 2rem;
  cursor: pointer;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Actions__2CepP .NotificationCenter_Icon__TddGI.NotificationCenter_Up__1PwW0 {
  color: #9ae89f;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Actions__2CepP .NotificationCenter_Icon__TddGI.NotificationCenter_Down__1I3dQ {
  color: #ff8c8c;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Unread__Hk4g- {
  position: absolute;
  height: calc(100% + 4px);
  top: -4px;
  left: -1.6rem;
  padding: 0rem 0rem;
}
.NotificationCenter_Notification__1SGTk .NotificationCenter_Unread__Hk4g- .NotificationCenter_Icon__TddGI {
  font-size: 0.8rem;
  color: #12AD56;
}
/*
.Settings {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -2px 11px 0px rgba(0,0,0,0.08);
  padding: 1rem 2.2rem 2rem;
}
.Settings .SettingsHeader {
  margin: 0rem 0rem 1rem;
}
.Settings .Title {
  font-weight: 600;
}
.Settings .Title {
  font-weight: 600;
}
*/

.NotificationsAccess_Access__2YVja {
  position: fixed;
  right: 2.6rem;
  top: 0.7rem;
  z-index: 2001;
  margin-left: 2rem;
  background-color: #efefef;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}
.NotificationsAccess_Access__2YVja img {
  position: absolute;
  left: 2rem;
  top: 2rem;
  cursor: pointer;
  transform: translate(-50%, -52%);
  height: 3.7rem;
}
.NotificationsAccess_Access__2YVja .NotificationsAccess_Bubble__tttXE {
  position: absolute;
  right: 0.4rem;
  font-size: 0.8rem;
  color: #12AD56;
}

.NotificationsAccess_Access__2YVja.NotificationsAccess_Big__2vvsW {
  width: 5.4rem;
  height: 5.4rem;
}
.NotificationsAccess_Access__2YVja.NotificationsAccess_Big__2vvsW img {
  height: 4.6rem;
  left: 2.8rem;
  top: 2.8rem;
}
.NotificationsAccess_Access__2YVja.NotificationsAccess_Big__2vvsW .NotificationsAccess_Bubble__tttXE {
  right: 0.8rem;
}

