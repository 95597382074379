.Script {
  margin-top: 1rem;
  color: #777777;
  line-height: 1.66666;
  margin-bottom: 3rem;
}

.Subtitle {
  color: #4c4c4c;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.ChipsContainer {
  position: relative;
  width: fit-content;
  margin-bottom: 2rem;
}
.ChipsBorder {
  box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.2);
  border-radius: 3rem;
}
.Chip {
  border-radius: 3rem;
  border: 0.1rem solid transparent;
  padding: 0.7rem 3rem 0.7rem;
  margin-right: 3rem;
  color: #777777;
  text-align: center;
  backgroundcolor: #fff;
  display: inline-block;
  cursor: pointer;
  zindex: 10;
  position: relative;
}
.Chip:last-child {
  margin-right: 0rem;
}
.Chip.Accepted:hover,
.Chip.Active.Accepted {
  color: #12AD56;
}
.Chip.NotProposed:hover,
.Chip.Active.NotProposed {
  color: #ccbd16;
}
.Chip.Rejected:hover,
.Chip.Active.Rejected {
  color: #f90000;
}

.Chip.Undecided:hover,
.Chip.Active.Undecided {
  color: #e87f19;
}

.Chip.Active {
  box-shadow: 0rem 0.2rem 0.4rem 0rem rgba(0, 0, 0, 0.2);
}

.Error {
  font-size: 1.1rem;
  padding: 0.7rem 0rem;
}
