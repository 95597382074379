.Title {
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 3.6rem;
  margin-bottom: 1.2rem;
  margin-top: 2rem;
}
.Close {
  text-align: right;
  cursor: pointer;
}
.Counter {
  background-color: #f5f5f5;
  padding: 0.4rem 1.6rem;
  border-radius: 13px;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.Icon {
  margin: 4rem 0rem;
  width: 11rem;
  height: auto;
  filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
}
.SummaryContainer {
  width: 80%;
  margin: 0rem auto;
}
.BtContainer {
  width: 80%;
  margin: 3rem auto 0rem;
}
