.Wrapper {
  background-color: #fff;
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  height: 100%;
}
.Current {
  background-color: #fcfcfc;
  padding: 2.2rem 2.4rem;
  min-height: 100%;
  text-align: center;
}
.Current .Title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Current .Icon {
  width: auto;
  height: 15rem;
  min-width: 5rem;
  max-width: 9.7rem;
}
.Current .Points {
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 1.5rem;
}

.Map {
  padding: 3rem 3.5rem;
  /*box-shadow: inset 35px 0px 35px -35px rgba(0,0,0,0.08);*/
  min-height: 100%;
}
.Map .Message {
  margin-bottom: 3rem;
}
.Map .Message span {
  font-weight: 700;
}
