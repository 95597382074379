.Wrapper {
  background-color: #fff;
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  padding: 2.2rem;
  height: 100%;
}

.Table .CellRank {
  width: auto;
  white-space: nowrap;
  border-bottom: none;
  padding-bottom: 0.6rem;
  font-weight: 600;
  fontsize: 1.2rem;
  color: #969696;
}
.Table .CellRank.Me {
  color: #00de7c;
}
.Table .CellAvatar {
  padding: 0rem 1.3rem;
  border-bottom: none;
  padding-bottom: 0.6rem;
}
.Table .CellAvatar Avatar {
  height: 4.2rem;
  width: 4.2rem;
}
.Table .CellProgressBar {
  width: 100%;
  border-bottom: none;
  padding-bottom: 0.6rem;
}
.Table .CellProgressBar .Bg {
  background-color: #f2f2f2;
  width: 100%;
  height: 4px;
  margin-bottom: 0.8rem;
  margin-top: 0.2rem;
}
.Table .CellProgressBar .Name {
  flex-grow: 1;
  color: #3a404d;
  font-size: 1.3rem;
  line-height: 1.2;
}
.Table .CellProgressBar .Points {
  color: #3a404d;
  font-size: 1.3rem;
  text-align: right;
}
.Table .CellProgressBar .Points.Me {
  color: #00de7c;
}
.Table .CellProgressBar .Progress {
  height: 4px;
}

.Target {
  text-align: center;
  line-height: 0;
}
.Target .Avatar {
  width: 7rem;
  height: auto;
  margin: 0 auto;
}
.Target .Dots img {
  margin: 0.8rem 0;
}
.Target .Message {
  width: 90%;
  transform: translateX(10%);
  font-size: 1.3rem;
  line-height: 1.4;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.LDTitle {
  padding: 2.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.3rem;
}
.LDClose {
  position: absolute;
  right: 2.4rem;
  top: 2.4rem;
  cursor: pointer;
}
.LDDivider {
  width: 100%;
  height: 1px;
  background-color: #e6e9ee;
}
.LDButtons {
  text-align: right;
  padding: 1.7rem 2.4rem;
}
.LDList {
  padding: 3rem 6rem 3rem 4rem;
}
