.FeedbackTitle {
  font-weight: 600;
  text-align: left;
  margin-bottom: 1.8rem;
}

.FeedbackCard {
  text-align: left;
  padding: 3rem;
  width: 28.4rem;
  height: 28rem;
  color: #777777;
  text-align: center;
}

.Level {
  margin-bottom: 2rem;
}

.KeyInfo .Key {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  background-color: #f5f5f5;
  padding: 0.3rem 0 0.6rem 0;
  border-radius: 3px;
  margin-right: 0.6rem;
  width: 9rem;
}
.KeyInfo .Key:last-child {
  margin-right: 0rem;
}
.KeyInfo .Key .Value {
  font-size: 2.4rem;
  line-height: 3.3rem;
  font-weight: 600;
}
.KeyInfo .Key .Label {
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 600;
  opacity: 0.5;
}
