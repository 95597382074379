.Bundle {
  position: fixed;
  z-index: 2001;
  right: 0.8rem;
  top: 0.6rem;
}
.Notification.Micro {
  width: auto;
  padding-right: 1.4rem;
  cursor: pointer;
}
.Notification.Micro .Message {
  font-size: 3rem;
  width: 7rem;
}
.Notification.Micro .Message {
  font-size: 3rem;
  padding: 0.6rem 2.2rem;
}

.Notification {
  width: 41.5rem;
  position: relative;
  height: 7rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 45px;
  box-shadow: 5px 12px 34px 0px rgba(175, 180, 187, 0.43);
}
.Notification.Exited {
  height: 0rem;
  padding: 0rem;
  margin-bottom: 0rem;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.Notification .Buddy {
  position: absolute;
  top: -1.7rem;
  left: -0.7rem;
}
.Notification .Buddy img {
  width: 10rem;
  height: auto;
}

.Notification .Message {
  width: 27.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 2rem;
  margin-left: 7rem;
  height: 4.9rem;
  overflow: hidden;
  background-color: #fff;
  padding: 0.4rem 2rem;
  border-radius: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Notification .Actions {
  position: absolute;
  right: 2.5rem;
  top: 0rem;
}
.Notification .Actions > div {
  margin: 1rem 0rem;
}
.Notification .Actions .Icon {
  font-size: 2rem;
  cursor: pointer;
}
.Notification .Actions .Icon.Up {
  color: #cdffd0;
}
.Notification .Actions .Icon.Down {
  color: #ffb1b1;
}
