@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.FadeIn {
  animation: fadeIn ease 0.4s;
  -webkit-animation: fadeIn ease 0.4s;
  -moz-animation: fadeIn ease 0.4s;
  -o-animation: fadeIn ease 0.4s;
  -ms-animation: fadeIn ease 0.4s;
}
@keyframes translate {
  from {
    padding-bottom: 15rem;
  }
  to {
    padding-bottom: 0rem;
  }
}
.Translate {
  animation: translate ease 0.4s;
  -webkit-animation: translate ease 0.4s;
  -moz-animation: translate ease 0.4s;
  -o-animation: translate ease 0.4s;
  -ms-animation: translate ease 0.4s;
}

.Wrapper {
  padding: 51px 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(0deg, transparent 50%, #ffffff 50%);
  min-height: 100vh;
}

.WrapperTop {
  /*position: relative;*/
  width: 100%;
  /*height: auto;*/
  /*height: calc(50vh - 51px);*/
  text-align: center;
  /*background-color: yellow;*/
  padding-bottom: 0rem;
  background-color: white;
  box-shadow: 0rem 0.3rem 0.4rem 0rem rgba(1, 0, 0, 0.02);
}
.WrapperTop.ShowAvatar {
  padding-bottom: 15rem;
}

.WrapperTop .Welcome {
  font-weight: 600;
  font-size: 4.1rem;
  line-height: 0.95;
  color: #4c4c4c;
  margin-bottom: 1.5rem;
}
.WrapperTop .Time {
  font-weight: 800;
}
.WrapperTop .Help {
  color: #6b6b6b;
  margin-bottom: 5rem;
}
.StartCall {
  margin-bottom: 1.5rem;
}

.WrapperBottom {
  width: 100%;
  padding: 3rem 2rem;
  background-color: #f5f7fa;
}
.Hidden {
  opacity: 0;
}
.WrapperBottom .FeedbackTitle {
  font-weight: 600;
  text-align: left;
  margin-top: 0.8rem;
  margin-bottom: 2.7rem;
}
.WrapperBottom .FeedbackCard {
  text-align: left;
  padding: 3rem;
  width: 28.4rem;
  height: 28rem;
  color: #777777;
}
.WrapperBottom .FeedbackCard .CustomerName {
  color: #4c4c4c;
  font-weight: 600;
  font-size: 1.8rem;
  min-height: 7.4rem;
}
.WrapperBottom .FeedbackCard .CustomerInfo {
  margin-bottom: 0.5rem;
}
.WrapperBottom .FeedbackCard .FeedbackStatus {
  font-weight: 600;
  margin-left: 1rem;
}
