.Wrapper {
  margin-top: 51px;
  box-shadow: inset 0px 2px 9px 0px rgba(1, 0, 0, 0.02);
  padding: 2.4rem 2.4rem 0rem;
  min-height: calc(100vh - 51px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Activity.Transparent {
  height: 0.3rem;
  background-color: #fff;
  width: calc(100vw + 2rem);
  position: fixed;
  top: 0;
  left: -2rem;
  z-index: 2000;
}
.Box {
  box-shadow: 0px 2px 70px -7px rgba(0, 0, 0, 0.06);
  border-radius: 6;
  background-color: #fff;
  min-height: calc(100vh - 120px);
}
.Box .Right {
  padding: 3.3rem 3.5rem;
}
