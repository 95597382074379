.badgesContainer {
  text-align: left;
  padding: 4.8rem;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.07);
  borderradius: 3px;
  margin-bottom: 2.4rem;
  background-color: #fff;
}
.Title {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.3rem;
  margin-bottom: 1.6rem;
}
.Message {
  margin-bottom: 5.2rem;
}
