.figuresTitle {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 0.9rem;
}
.figureWrapper {
  display: inline-block;
  margin-right: 2rem;
}
.figureLabel {
  font-size: 1rem;
  text-transform: uppercase;
  color: #3a404d;
  opacity: 0.9;
}
.figureValue {
  font-size: 1.8rem;
}
.divider {
  margin-bottom: 3rem;
}
