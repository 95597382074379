.Progress {
  position: relative;
  margin: 0rem 1rem;
  min-height: 12rem;
}

.CurrentLevelIcon {
  position: absolute;
  top: 7.3rem;
  left: 0.7rem;
  transform: translateX(-50%);
  height: 3.6rem;
  width: auto;
}
.PrevPts {
  position: absolute;
  top: 10.9rem;
  left: 0.7rem;
  transform: translateX(-50%);
  font-size: 1.3rem;
}
.NextLevelIcon {
  position: absolute;
  top: 7.3rem;
  right: 0.7rem;
  transform: translateX(50%);
  height: 3.6rem;
  width: auto;
}
.NextPts {
  position: absolute;
  top: 10.9rem;
  right: 0.7rem;
  transform: translateX(50%);
  font-size: 1.3rem;
}

.Bar {
  position: relative;
  top: 0;
  left: 3rem;
  width: calc(100% - 6rem);
}
.Bar .Avatar {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  width: 6.2rem;
  height: auto;
  z-index: 20;
  border: 1px solid #cecece;
}
.Bar .Line {
  position: absolute;
  top: 6.2rem;
  left: 0;
  width: 2px;
  height: 2.7rem;
  background-color: #cecece;
  z-index: 20;
}
.Bar .BgBar {
  position: absolute;
  top: 8.9rem;
  left: 0rem;
  width: 100%;
  height: 0.9rem;
  background-color: #dadada;
  border-radius: 5px;
}
.Bar .CurrentLevelBar {
  position: absolute;
  top: 8.9rem;
  left: 0rem;
  width: 0;
  height: 0.9rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Bar .CurrentPts {
  position: absolute;
  top: 10.9rem;
  left: 0;
  transform: translateX(-50%);
  font-size: 1.3rem;
  font-weight: 600;
  white-space: nowrap;
  background-color: #fff;
  z-index: 20;
  padding: 0px 5px;
}
