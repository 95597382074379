.Status {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  padding: 0.6rem;
  border-radius: 3px;
}
.Upcoming {
  background-color: #659ad0;
}
.Ongoing {
  background-color: #3fbb77;
}
.Archived {
  background-color: #c5c5c5;
}

.Icon {
  font-size: 1.8rem;
  margin-right: 1rem;
}
.Icon.Up {
  color: #7ed283;
}
.Icon.Down {
  color: #ff8c8c;
  vertical-align: middle;
}
.Icon.Trash {
  font-size: 1.6rem;
  color: #d85656;
  opacity: 1;
  cursor: pointer;
}
.Icon.Trash.Disabled {
  opacity: 0.3;
  cursor: default;
}
.Icon.Add {
  font-size: 1.6rem;
  color: #13ae57;
}
.AndOr {
  height: 3.1rem;
  width: 12.6rem;
  position: relative;
}
.BtAndOr {
  background-color: #fff;
  border-radius: 22px;
  text-align: center;
  padding: 0.4rem 2rem;
  font-weight: 600;
  position: absolute;
}
.BtAndOr:last-child {
  right: 0;
}
.BtAndOr:first-child.Unselected {
  padding: 0.4rem 3rem 0.4rem 2rem;
}
.BtAndOr:last-child.Unselected {
  padding: 0.4rem 2rem 0.4rem 3rem;
}
.BtAndOr.Unselected {
  border: 1px solid #bcbcbc;
  color: #787878;
  z-index: 9;
  cursor: pointer;
}
.BtAndOr.Selected {
  border: 1px solid #13ae57;
  color: #13ae57;
  z-index: 10;
}

.AddGroupWrapper {
  position: relative;
  margin: 0.5rem 0rem 1.5rem;
}
.AddGroupWrapper .Line {
  position: absolute;
  left: 0px;
  top: 14px;
  border-bottom: 2px #bcbcbc dashed;
  height: 2px;
  width: 100%;
}
.AddGroupWrapper .Line.Left {
  left: 0px;
  width: 40%;
}
.AddGroupWrapper .Line.Right {
  left: 60%;
  width: 40%;
}
.AddGroupWrapper .AddGroup {
  cursor: pointer;
  position: absolute;
  top: 4px;
  left: 40%;
  width: 20%;
  text-align: center;
}
