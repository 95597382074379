.Wrapper {
  box-shadow: inset -35px 0px 35px -35px rgba(0, 0, 0, 0.08);
  min-height: 100%;
  padding: 2.4rem 3.5rem;
}

.Profile {
  text-align: center;
  margin-bottom: 4rem;
}
.Profile .Name {
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 2.3rem;
}
.Profile .Key {
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  background-color: #f5f5f5;
  padding: 0.3rem 0 0.6rem 0;
  border-radius: 3px;
  margin-right: 0.6rem;
  width: 9rem;
}
.Profile .Key:last-child {
  margin-right: 0rem;
}
.Profile .Key .Value {
  font-size: 2.9rem;
  line-height: 3.9rem;
  font-weight: 600;
}
.Profile .Key .Label {
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 600;
  opacity: 0.5;
}
