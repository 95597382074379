.Wrapper {
  width: 17rem;
  height: 12.2rem;
  padding: 1.4rem;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  text-align: center;
  background-color: #fff;
}
.Image {
  margin: 0 auto;
  margin-bottom: 0.8rem;
  height: 6rem;
  width: 6rem;
  opacity: 0.7;
  filter: grayscale(100%);
}
.Image img {
  height: 100%;
  width: auto;
  filter: drop-shadow(7px 8px 7px rgba(0, 0, 0, 0.12));
}
.Name {
  font-weight: 600;
  line-height: 2rem;
}
