.Message {
  margin-bottom: 3rem;
}
.Message span {
  font-weight: 700;
}
.Path {
  /*background: yellow;*/
}
.PathLine {
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  /*background: pink;*/
  background: #fff;
  border-radius: 4rem;
  padding: 2.2rem 3rem;
  margin-top: 3rem;
}
.Tick {
  position: absolute;
  font-size: 2.2rem;
  line-height: 2.2rem;
  color: #eeeeee;
}
.Tick.active {
  color: #13ae57;
}
.BgBar {
  position: relative;
  background: #eeeeee;
  border-radius: 1.1rem;
  height: 2.2rem;
  margin-left: 4rem;
}
.FillBar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #0000ff;
  border-radius: 1.1rem;
  height: 2.2rem;
}
.PtsBar {
  position: absolute;
  right: 0.6rem;
  top: 2.3rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.PathLevels {
  padding: 2.2rem 3rem;
}

.LevelGridItem {
  /*background-color: orange;*/
  /*border: 1px solid red;*/
}
.LevelCard {
  background-color: #fff;
  /*border: 1px solid grey;*/
  box-shadow: 1px 5px 16px 0px rgba(0, 0, 0, 0.05);
  text-align: center;
  padding: 1rem 1rem;
  border-radius: 3px;
  /*background-color: pink;*/
  width: fit-content;
  /*min-width: 10rem;*/
  /*max-width: 20rem;*/
}
.LevelCard .Title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.LevelCard .Icon {
  /*background-color: yellow;*/
  max-width: 30rem;
  /*max-width: 9rem;*/
  /*min-width: 5rem;*/
  /*max-width: 9.7rem;*/
}
.LevelCard .Icon img {
  height: 100%;
  /*  max-width: 100%*/
  padding: 0rem 1rem;
}
.LevelCard .Points {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.5rem;
  white-space: nowrap;
}
.Avatar {
  position: absolute;
  top: -5.5rem;
  left: 0rem;
  transform: translateX(-50%);
  width: 6.2rem;
  height: auto;
  z-index: 20;
  border: 1px solid #cecece;
}
.Line {
  position: absolute;
  top: -1.6rem;
  left: -0.5rem;
  width: 2px;
  height: 1.7rem;
  background-color: #cecece;
  z-index: 20;
}
