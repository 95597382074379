.Wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.Content {
  margin: 0rem auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Right {
  background-color: #fff;
  border-radius: 18px;
  height: 65vh;
  width: 41vw;
  min-width: 20rem;
  max-width: 55rem;
  min-height: 50rem;
  position: relative;
}
.ClientLogo {
  width: 15rem;
  /*width: auto;*/
  /*height: 2.6rem;*/
  position: absolute;
  right: 3rem;
  top: 2rem;
}
.Form {
  /*padding: 4rem;*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -44%);
  width: 70%;
}
.Title {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.4rem;
}
.Help {
  font-size: 1.6rem;
  font-weight: 300;
  opacity: 0.6;
  margin-bottom: 3.6rem;
}
.InputLabel {
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.Error {
  height: 5rem;
  font-size: 1.1rem;
  padding: 0.7rem 0rem;
}
.Left {
  position: relative;
  margin-right: 8vw;
}
.Mascot {
  position: absolute;
  top: 0;
  left: -4rem;
}
.Mascot img {
  width: 21rem;
  height: auto;
}
.Cmb {
  padding-left: 13.5rem;
  font-size: 8.7rem;
  font-family: 'proxima-nova, sans-serif';
  font-weight: 700;
  line-height: 1;
  color: #fff;
}

@media (max-width: 1680px) {
  .Form {
    width: 70%;
  }
  .Right {
    height: 65vh;
    width: 35vw;
  }
  .Title {
    font-size: 2.8rem;
  }
  .Left {
    position: relative;
    margin-right: 5vw;
  }
  .ClientLogo {
    /*height: 2.2rem;*/
  }
  .Mascot {
    top: -0.8rem;
    left: -7rem;
  }
  .Mascot img {
    width: 16rem;
  }
  .Cmb {
    padding-left: 6rem;
    font-size: 6.5rem;
    font-family: 'proxima-nova, sans-serif';
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
}
@media (max-width: 1080px) {
  .Form {
    width: 75%;
  }
  .Right {
    height: 65vh;
    width: 35vw;
  }
  .Title {
    font-size: 2.2rem;
  }
  .ClientLogo {
    /*height: 2.2rem;*/
  }
  .Mascot {
    top: -0.8rem;
    left: -5.7rem;
  }
  .Mascot img {
    width: 14rem;
  }
  .Cmb {
    padding-left: 6rem;
    font-size: 5rem;
    font-family: 'proxima-nova, sans-serif';
    font-weight: 700;
    line-height: 1;
    color: #fff;
  }
}
