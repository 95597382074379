.Wrapper {
  width: 24rem;
  height: 26rem;
  padding: 2.4rem 2rem 2.2rem;
  background-color: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  position: relative;
  text-align: center;
}
.Image {
  margin: 0 auto;
  margin-bottom: 1rem;
  height: 10rem;
  width: 10rem;
}
.Image img {
  height: 100%;
  width: auto;
  filter: drop-shadow(3px 4px 5px rgba(0, 0, 0, 0.14));
}
.Name {
  font-weight: 600;
}
.Description {
  font-size: 1.3rem;
}
